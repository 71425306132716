import { intl } from 'di18n-react';
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-shadow */
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { MrParcel } from '@/utils/tenon.js';
import classnames from 'classnames/bind';
import { EditorConfig, EditorMicroUrl, EditorName } from '@/constants/editor';
import usePermission from '@/hooks/usePermission';
import { tenonHtmlBootstrap, getIsWikiHTML } from '@/constants/wikihtmlrenderHelper';
import styles from './style.module.less';

const cls = classnames.bind(styles);

window.msContainer = true;
function Editor(props) {
  const {
    changeView,
    docInfo,
    changeInitLoading,
    profile,
    versionList,
    currentVersionIndex,
    comparisonVersionIndex,
  } = props;
  const { guid, accessToken, latestVersion, permission } = docInfo;
  const { checkOperationPermission } = usePermission();

  const versionComparisonRepeat = (index) => {
    const currentVersion = versionList[index];
    return currentVersion ? currentVersion.version : latestVersion;
  };

  const pageTitleComparisonRepeat = (index) => {
    const currentVersion = versionList[index];
    return currentVersion ? currentVersion.title : docInfo.pageName;
  };
  const version = useMemo(() => {
    return versionComparisonRepeat(currentVersionIndex);
  }, [versionList, currentVersionIndex, latestVersion]);

  const pageTitle = useMemo(() => {
    return pageTitleComparisonRepeat(currentVersionIndex);
  }, [versionList, currentVersionIndex, docInfo]);

  const comparisonVersion = useMemo(() => {
    return comparisonVersionIndex !== ''
      ? versionComparisonRepeat(comparisonVersionIndex)
      : null;
  }, [comparisonVersionIndex, docInfo, versionList]);

  /**
   * 通过list的version判断新旧版本
   * return {
   *    newDoc,
   *    oldDoc
   * }
   *
   */
  const versionComparison = useMemo(() => {
    if (comparisonVersionIndex === '') {
      return false;
    }
    let maxList = {};
    let minList = {};
    const headerVersionList = versionList[comparisonVersionIndex];
    const leftVersionList = versionList[currentVersionIndex];

    // eslint-disable-next-line max-len
    if (parseInt(comparisonVersion, 10) > parseInt(version, 10)) {
      maxList = headerVersionList;
      minList = leftVersionList;
    } else {
      minList = headerVersionList;
      maxList = leftVersionList;
    }
    const newDoc = {
      version: maxList.version,
      title: maxList.title,
    };
    const oldDoc = {
      version: minList.version,
      title: minList.title,
    };
    return {
      newDoc,
      oldDoc,
    };
  }, [versionList, currentVersionIndex, comparisonVersionIndex]);

  const userInfo = {
    username: profile.username,
    username_zh: profile.username_zh,
    avatar: profile.avatar,
  };

  /**
   * 版本对比 - 新增参数
   */
  const par = comparisonVersionIndex !== ''
    ? {
      version: true,
      versionCof: {
        docId: guid,
        ...versionComparison,
      },
    }
    : '';

  const editorConfig = {
    ...EditorConfig,
    menu: {
      floatingMenuContent: false,
      menuBarContent: null,
    },
    eventHandler: {
      init: (state, view) => {
        changeInitLoading(false);
        changeView(view);
      },
    },
    title: {
      value: pageTitle,
      withTitle: true,
      placeholder: intl.t('无标题页面'),
      maxLength: 200,
    },
    app: {
      sourceId: versionList[currentVersionIndex]?.guid || guid,
      accessToken,
      sourceType: '6',
      content: '',
      dataLocation: 'self',
      version,
    },
    placeholder: {
      content: intl.t('直接输入内容，或选取模板进行新建'),
      switch: true,
    },
    comment: {
      show: false,
      editable: false,
    },
    dictionary: {
      show: false,
    },
    editable: false,
    ...par,
    user: {
      ...userInfo,
      nameCn: userInfo.username_zh,
    },
    filePermission: {
      download: checkOperationPermission('MANAGE_PAGE_CONTEXT', permission),
    },
  };

  const editorShow = useMemo(() => {
    return guid && userInfo.username && version;
  }, [guid, userInfo, version]);

  useEffect(() => {
    if (docInfo.pageName) {
      window.document.title = docInfo.pageName;
    }
  }, [docInfo.pageName]);

  useEffect(() => {
    return () => {
      // 页面切换时卸载编辑器
      if (editorShow) {
        const TenonEditor = window[EditorName];
        if (TenonEditor && typeof TenonEditor.unmount === 'function') { // 监控到白屏报错做修复
          TenonEditor.unmount();
        }
      }
    };
  }, []);
  const currentVersionDocInfo = versionList?.[currentVersionIndex];
  return (
    <div
      id="knowledge_editor_box"
      className={cls('editor')}>
      {/* key 作为是否重新渲染editor的标识，key变化，editor会重新渲染 */}
      <div
        key={currentVersionDocInfo?.historyName || latestVersion}
        className={cls('editor-container')}
      >
        {editorShow && (
          <>
            {
              getIsWikiHTML(currentVersionDocInfo)
                ? <MrParcel
                    entry={currentVersionDocInfo?.contentUrl}
                    name="knowledge_wiki_html"
                    appProps={{
                      app: tenonHtmlBootstrap(() => {
                        changeInitLoading(false);
                        // changeEditReady(true);
                      }),
                    }}
                  />
                : <MrParcel
                    entry={EditorMicroUrl}
                    name={EditorName}
                    appProps={editorConfig}
                  />
            }
          </>
        )}
      </div>
    </div>
  );
}

function mapStateToProps({ historyVersion, pageDetail, CooperIndex }) {
  const { docInfo } = pageDetail;
  const { versionList, currentVersionIndex, comparisonVersionIndex } = historyVersion;
  const { profile } = CooperIndex;
  return {
    docInfo,
    profile,
    versionList,
    currentVersionIndex,
    comparisonVersionIndex,
  };
}

function mapDispatchToProps({ historyVersion }) {
  const { changeView, changeInitLoading, changeComparisonVersion } = historyVersion;
  return {
    changeView,
    changeInitLoading,
    changeComparisonVersion,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
