import { intl } from 'di18n-react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { MrParcel } from '@didi/mf-tenon';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import classnames from 'classnames/bind';
import { EditorConfig, EditorMicroUrl, EditorName } from '@/constants/editor';
import { getTemplateDetail } from '@/service/knowledge/template';
import styles from './style.module.less';

const cls = classnames.bind(styles);

window.msContainer = true;
function Editor(props) {
  const [editorView, setEditorView] = useState(null);
  const { knowledgeId } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);
  const { profile, templateId } = props;
  const userInfo = {
    username: profile.username,
    username_zh: profile.username_zh,
    avatar: profile.avatar,
  };

  const editorConfig = {
    ...EditorConfig,
    editable: false,
    schema: {
      nodes: [
        'doc',
        'paragraph',
        'text',
        'blockquote',
        'list_item',
        'bullet_list',
        'ordered_list',
        'heading',
        'image_block',
        'image',
        'hard_break',
        'checklist',
        'checklist_item',
        'code_block',
        'expand',
        'horizontal_rule',
        'file_block',
        'file',
        'table',
        'table_cell',
        'table_row',
        'table_header',
        'mathquill',
        'iframe',
        'flowchart',
        'block_column',
        'block_row',
        'link_card',
        'link_text',
        'contain_page',
      ],

      marks: [
        'link',
        'em',
        'strong',
        'textHighlight',
        'tabindent',
        'strikethrough',
        'underline',
        'textColor',
        'fontsize',
        'code',
        'fontfamily',
        'subscript',
        'superscript',
        'formatBrush',
        'formatPainter',
        'collabUser',
      ],
    },
    markdownGuide: false,
    menu: {
      floatingMenuContent: false,
      menuBarContent: null,
    },
    eventHandler: {
      init: (state, view) => {
        setEditorView(view);
      },
    },
    title: {
      value: '',
      withTitle: true,
      placeholder: intl.t('无标题页面'),
      maxLength: 200,
    },
    app: {
      sourceId: 'templateEditor', // 随便取一个id，但要保证页面上全局唯一
      sourceType: '6',
      content: '',
      dataLocation: 'other',
    },
    placeholder: {
      content: intl.t('直接输入内容，或选取模板进行新建'),
      switch: true,
    },
    comment: {
      show: false,
      editable: false,
    },
    dictionary: {
      show: false,
    },
    offline: true,
    // mode: 'offline',
    user: {
      ...userInfo,
      nameCn: userInfo.username_zh,
    },
  };

  const editorShow = useMemo(() => {
    return userInfo.username && !!templateId;
  }, [userInfo, templateId]);

  useEffect(() => {
    return () => {
      // 页面切换时卸载编辑器
      const TenonEditor = window[EditorName];
      if (TenonEditor && typeof TenonEditor.unmount === 'function') { // 监控到白屏报错做修复
        TenonEditor.unmount();
      }
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    if (templateId && editorView) {
      const params = {
        knowledgeId,
        templateId,
      };
      getTemplateDetail(params).then(({ content }) => {
        content && editorView.template.import(JSON.parse(content));
        setLoading(false);
      });
    }
  }, [templateId, editorView]);

  return (
    <div
      id="knowledge_editor_box"
      className={cls('editor')}>
      {loading && (
        <div className={cls('loading')}>
          <Spin wrapperClassName={'loading'} />
        </div>
      )}

      <div className={cls('editor-container')}>
        {editorShow && (
          <MrParcel
            entry={EditorMicroUrl}
            name={EditorName}
            appProps={editorConfig}
          />
        )}
      </div>
    </div>
  );
}

function mapStateToProps({ CooperIndex }) {
  const { profile } = CooperIndex;
  return {
    profile,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
